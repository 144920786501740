<template>
 <div>
  <sdPageHeader title="Apps">
  </sdPageHeader>
  <Main>
   <a-row :gutter="25" justify="center" type="flex">
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="secondary">Actief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/ecedo.png`)"
        alt="ecedo"
        style="max-width:100px"
       />
      </sdHeading>
      <span class="package-user-type">Software voor energieleveranciers</span>
      <ListGroup>
       <List text="Energie-inkoop"/>
       <List text="Facturatie"/>
       <List text="Calculatie"/>
       <List text="Workflowmanagement"/>
       <List text="Data-uitwisseling met andere partijen en applicaties"/>
      </ListGroup>
      <sdButton size="default" type="primary">Configuratie</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="secondary">Actief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/gridhub.svg`)"
        alt="ecedo"
        style="max-width:100px"
       />
      </sdHeading>
      <span class="package-user-type">Energie flow & Product configurator</span>
      <ListGroup>
       <List text="Energie componenten"/>
       <List text="Standard templates"/>
       <List text="Aanbod op maat"/>
       <List text="Orderflow"/>
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton size="default" type="danger"> Deactiveren</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/twikey.svg`)"
        alt="twikey"
        style="max-width:100px;"
       />
      </sdHeading>
      <span class="package-user-type">Simplify payments for customers</span>
      <ListGroup>
       <List text="e-Mandates"/>
       <List text="Sepa Direct Debits"/>
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton size="default" type="success"> Activeren</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/surepay.svg`)"
        alt="surepay"
        style="max-width:100px;"
       />
      </sdHeading>
      <span class="package-user-type">Beschermt tegen fraude</span>
      <ListGroup>
       <List text="IBAN-Naam Check voor Banken"/>
       <List text="IBAN-Naam Check voor Bedrijven"/>
       <List text="De Overstap Check"/>
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton size="default" type="success"> Activeren</sdButton>
     </PricingCard>
    </a-col>
   </a-row>
   <a-row :gutter="25" justify="center" type="flex">
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/creditsafe.png`)"
        alt="ecedo"
        style="max-width:100px;"
       />
      </sdHeading>
      <span class="package-user-type">Beheren financiële risico’s</span>
      <ListGroup>
       <List text="Informatieportal"/>
       <List text="Risico-indicatoren"/>
       <List text="Kredietrapport"/>
      </ListGroup>
      <sdButton size="secondary" type="success">Activeren</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/gridhub.svg`)"
        alt="ecedo"
        style="max-width:100px"
       />
      </sdHeading>
      <span class="package-user-type">Debiteuren en Incasso flow</span>
      <ListGroup>
       <List text="SMS & E-mail Herrineringen"/>
       <List text="Voldoet aan de Energiewetgeving"/>
       <List text="Prenotify EOS en automatisch triggeren van EOS"/>
       <List text="Configuratie naar wens van de leverancier"/>
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton type="primary"> Configuratie</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/aangetekend-mailen.svg`)"
        alt="twikey"
        style="max-width:100px;"
       />
      </sdHeading>
      <span class="package-user-type">Digitaal, Beveiligd en Rechtsgeldig</span>
      <ListGroup>
       <List text="Aangetekende e-mails"/>
       <List text="Eenvoudig te integreren"/>
       <List text="Minimale IT-impact"/>
       <List text="Workflow zodat documenten volledig geautomatiseerd"/>
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton size="default" type="success"> Activeren</sdButton>
     </PricingCard>
    </a-col>
    <a-col :lg="8" :sm="12" :xs="24" :xxl="6">
     <PricingCard style="margin-bottom: 30px">
      <Badge class="pricing-badge" type="danger">Inactief</Badge>
      <sdHeading as="h3" class="pricing-title" style="height: 45px">
       <img
        :src="require(`@/static/img/vendor/postbode.png`)"
        alt="surepay"
        style="max-width:100px;"
       />
      </sdHeading>
      <span class="package-user-type">De online Postkamer</span>
      <ListGroup>
       <List text="Fulfilment"/>
       <List text="Enveloppen"/>
       creditsafe.png
       <!--       <List text="Custom Post Types"/>-->
      </ListGroup>
      <sdButton size="default" type="success"> Activeren</sdButton>
     </PricingCard>
    </a-col>
   </a-row>
  </Main>
 </div>
</template>

<script>
import List from '../../components/pricing/pricing';
import {defineComponent} from 'vue';
import {Main} from "@/view/styled";
import {Badge, ListGroup, PricingCard} from "@/view/404/style";

export default defineComponent({
 name: 'Sidebar',
 components: {
  Main,
  List,
  PricingCard,
  ListGroup,
  Badge,
 },
 data() {
  return {};
 },
});
</script>
